import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/common/layout'
import SEO from '../components/common/seo'

const BioPage = ({ data }) => {
	const person = data.person
	const gallery = data.gallery.media
	const pageDetails = data.bio.edges[0].node

	return (
		<Layout pageTitle={pageDetails.title}>

			<SEO
				title={pageDetails.title}
				description={pageDetails.description}
				img={pageDetails.shareImage.file.url}
			/>

			<div className="columns is-centered">
				<div className="column is-6">
					<p
						className="content is-size-5 bio-content"
						dangerouslySetInnerHTML={{ __html: person.shortBio.shortBio }}
					/>
					<p className="has-text-centered	">
						<span>Scroll images below</span><br />
						<i className="ri-arrow-left-right-line" />
					</p>
				</div>
			</div>

			<div className="columns is-centered is-mobile scrollpics">
				{gallery.map((image) =>
					<div className="column is-4">
						<Img fluid={image.fluid} title={image.title} alt={image.title} />
					</div>
				)}
			</div>

			<div className="columns has-text-right">
				<div className="column">
					<Link to="/gallery">View more pictures <span className="icon align-icon"><i className="ri-arrow-right-circle-line"></i></span></Link>
				</div>
			</div>
		</Layout>
	)
}

export default BioPage

export const BioQuery = graphql`
{
	person:contentfulPerson(name: {eq: "Toyosi Soetan"}) {
		email
		deezer
		napster
		tidal
		instagram
		spotify
		youtube
		title
		name
		shortBio {
          shortBio
        }
		image {
			fluid(maxWidth: 1000) {
				...GatsbyContentfulFluid_tracedSVG
			}
		}
	}
	gallery:contentfulGallery(title: {eq: "Biography"}) {
            title
            description {
                content {
                    content {
                        value
                    }
                }
            }
            media {
				description
                title
                file {
                    url
                }
                fluid(maxWidth: 1920) {
					...GatsbyContentfulFluid
                }
            }
		}
	bio:allContentfulPageDetails(filter: {title: {eq: "Bio"}}) {
      	edges {
			node {
				title
				description
				shareImage {
					file {
						url
					}
				}
			}
      	}
    }
}
`